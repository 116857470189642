$(document).ready(function() {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  $("#menu")
    .before(
      '<button class="hamburger hamburger--squeeze" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="vh">Menú</span></button>'
    )
    .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");

  toggleMenu.addEventListener("click", function() {
    var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
    toggleMenu.setAttribute("aria-expanded", !open);
    menu.hidden = !menu.hidden;
  });

  $(".hamburger").on("click", function() {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    // $("body").toggleClass("js-menu-open");
  });

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function() {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // ----------
  // scroll top
  // ----------
  $(window).scroll(function() {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").addClass("is-visible");
    } else {
      $(".scrolltop").removeClass("is-visible");
    }
  });

  // ----------------------
  // arreglar estils ul.rel
  // ----------------------
  $(".rel li a")
    .filter(function() {
      return (
        $(this).text() ==
        $(this)
          .parent()
          .text()
      );
    })
    .each(function(i, el) {
      $(el).addClass("onlyLink");
    });

  // ---------------
  // borders nav-sub
  // ---------------
  $(".sub-nav a").hover(
    function() {
      $(this)
        .parent()
        .prev("li")
        .children("a")
        .addClass("prev");
    },
    function() {
      $(this)
        .parent()
        .prev("li")
        .children("a")
        .removeClass("prev");
    }
  );
  $(".sub-nav .active")
    .prev("li")
    .children("a")
    .addClass("prev2");

  // ------
  // slider
  // ------
  $(".slider").slick({
    infinite: true,
    fade: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false
  });

  // --------
  // headroom
  // --------
  $(".main-header").headroom({
    offset: 205,
    tolerance: 5,
    classes: {
      initial: "animated",
      pinned: "slideDown",
      unpinned: "slideUp"
    }
  });

  // -------
  // Toggles
  // -------
  function collapse(toggle) {
    var collapsibleBox = document.getElementById(
      toggle.getAttribute("data-a11y-toggle")
    );
    collapsibleBox.setAttribute("aria-hidden", true);
    toggle.setAttribute("aria-expanded", false);
  }

  function collapseAll(event) {
    toggles
      .filter(function(t) {
        return t !== event.target;
      })
      .forEach(collapse);
  }

  var toggles = Array.prototype.slice.call(
    document.querySelectorAll(".connected-toggles [data-a11y-toggle]")
  );

  toggles.forEach(function(toggle) {
    toggle.addEventListener("click", collapseAll);
  });

  //amagar toggles desplegats al clicar fora
  $("body").click(function(e) {
    var allyAttribute = $(e.target).attr("data-a11y-toggle");
    if (
      (typeof allyAttribute === typeof undefined || allyAttribute === false) &&
      $(e.target).closest("#searchForm").length == 0
    ) {
      $("#cnlList, #xxssList, #searchForm, #cnlList-L").attr("aria-hidden", "true");
    }
  });


  // -----------
  // Collapsible (https://inclusive-components.design/collapsible-sections/)
  // -----------

  if ($(".collapsible")[0]) {
    (function() {
      var headings = document.querySelectorAll(".collapsible__title");
      Array.prototype.forEach.call(headings, function(h) {
        var btn = h.querySelector("button");
        var target = h.nextElementSibling;

        btn.onclick = function() {
          var expanded = btn.getAttribute("aria-expanded") === "true";
          btn.setAttribute("aria-expanded", !expanded);
          target.hidden = expanded;
        };
      });
    })();
  }
  

  // ----
  // Tabs https://inclusive-components.design/tabbed-interfaces/
  // ----

  if ($(".tabbed").length > 0) {
    $(".tabbed").each(function(index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector("ul.tab-menu");
      var tabs = tablist.querySelectorAll("a");
      var panels = tabbed.querySelectorAll(".panel");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function(tab, i) {
        tab.setAttribute("role", "tab");
        //      tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function(e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function(e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
              ? index + 1
              : e.which === 40
              ? "down"
              : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
              ? switchTab(e.currentTarget, tabs[dir])
              : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function(panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }


  // ----
  // Tabs editora hospitals
  // ----

  if ($(".tabbed-hosp").length > 0) {
    $(".tabbed-hosp").each(function(index, element) {
      // Get relevant elements and collections
      var tabbed = element;
      var tablist = tabbed.querySelector(".tab-menu-hosp");
      var tabs = tablist.querySelectorAll(".tab-menu-hosp li a");
      var panels = tabbed.querySelectorAll(".panel-hosp");

      // The tab switching function
      var switchTab = function switchTab(oldTab, newTab) {
        newTab.focus(); // Make the active tab focusable by the user (Tab key)

        newTab.removeAttribute("tabindex"); // Set the selected state

        newTab.setAttribute("aria-selected", "true");
        oldTab.removeAttribute("aria-selected");
        oldTab.setAttribute("tabindex", "-1"); // Get the indices of the new and old tabs to find the correct
        // tab panels to show and hide

        var index = Array.prototype.indexOf.call(tabs, newTab);
        var oldIndex = Array.prototype.indexOf.call(tabs, oldTab);
        panels[oldIndex].hidden = true;
        panels[index].hidden = false;
      }; // Add the tablist role to the first <ul> in the .tabbed container

      tablist.setAttribute("role", "tablist"); // Add semantics are remove user focusability for each tab

      Array.prototype.forEach.call(tabs, function(tab, i) {
        tab.setAttribute("role", "tab");
        //      tab.setAttribute("id", "tab" + (i + 1));
        tab.setAttribute("tabindex", "-1");
        tab.parentNode.setAttribute("role", "presentation"); // Handle clicking of tabs for mouse users

        tab.addEventListener("click", function(e) {
          e.preventDefault();
          var currentTab = tablist.querySelector("[aria-selected]");

          if (e.currentTarget !== currentTab) {
            switchTab(currentTab, e.currentTarget);
          }
        }); // Handle keydown events for keyboard users

        tab.addEventListener("keydown", function(e) {
          // Get the index of the current tab in the tabs node list
          var index = Array.prototype.indexOf.call(tabs, e.currentTarget); // Work out which key the user is pressing and
          // Calculate the new tab's index where appropriate

          var dir =
            e.which === 37
              ? index - 1
              : e.which === 39
              ? index + 1
              : e.which === 40
              ? "down"
              : null;

          if (dir !== null) {
            e.preventDefault(); // If the down key is pressed, move focus to the open panel,
            // otherwise switch to the adjacent tab

            dir === "down"
              ? panels[i].focus()
              : tabs[dir]
              ? switchTab(e.currentTarget, tabs[dir])
              : void 0;
          }
        });
      }); // Add tab panel semantics and hide them all

      Array.prototype.forEach.call(panels, function(panel, i) {
        panel.setAttribute("role", "tabpanel");
        panel.setAttribute("tabindex", "-1");
        var id = panel.getAttribute("id");
        panel.setAttribute("aria-labelledby", tabs[i].id);
        panel.hidden = true;
      }); // Initially activate the first tab and reveal the first tab panel

      tabs[0].removeAttribute("tabindex");
      tabs[0].setAttribute("aria-selected", "true");
      panels[0].hidden = false;
    });
  }

  // ----------
  // Datepicker
  // ----------
  $(function() {
    $(".date").fdatepicker({
      format: "dd-mm-yyyy",
      language: "es",
      weekStart: 1,
      leftArrow:
        '<button type="button"><img src="/media/img/bt-calendario-anterior.png" srcset="/media/img/bt-calendario-anterior@2x.png 2x" width="24" height="24" alt="Mes anterior" /></button>',
      rightArrow:
        '<button type="button"><img src="/media/img/bt-calendario-siguiente.png" srcset="/media/img/bt-calendario-siguiente@2x.png 2x" width="24" height="24" alt="Mes siguiente" /></button>'
    });
  });
});
